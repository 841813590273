.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white !important;
}

.navbar-dark .navbar-brand {
  color: white !important;
}

a {
  color: #4cdbc4 !important;
}

h1, h2, h3, h4, h5 {
  font-family: 'Bowlby One SC', cursive;
  letter-spacing: .025em;
}

.calculator {
  background-color: #f5f5f5;
  padding: 20px;
  margin: 20px 0px 20px 0px;
  border: 1px solid #b2d6d0;
  border-radius: 5px;
}

.prettyTable th, .prettyTable td {
  padding: 5px;
  border: 1px #aaaaaa solid;
  text-align: center;
}

.prettyTable {
  float: right;
  margin: 10px;
  border-collapse: collapse;
}

p {
  font-family: 'Open Sans',sans-serif;
  color: #313030;
  letter-spacing: .025em;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  h1, h2 {
    font-size: 5vw;
  }
  h3, h4 {
    font-size: 4vw;
  }
  h5 {
    font-size: 3vw;
  }
  .sidebarStyle {
    margin-top: 20px;
  }
}

.mb-5, .my-5 {
  margin-bottom: 1rem !important;
}

.iconsStyle {
  color: #605f5f;
  margin-right: 5px;
  width: 16px;
  height: 16px;
}

.contentStyle {
  margin-top: '30px'; 
}

.textAd-a {
  color: black !important;
}

.textAd-div {
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #b2d6d0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dropdown-menu-item {
  vertical-align: middle;
}

/* latin-ext */
@font-face {
  font-family: 'Bowlby One SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Bowlby One SC'), url(./assets/fonts/BowlbyOneSC-Regular.ttf) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Bowlby One SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Bowlby One SC'), url(./assets/fonts/BowlbyOneSC-Regular.ttf) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.fraction-top{border-bottom:solid black 1px; display:inline-block;}
.fraction-bottom{ display:inline-block; clear:left;}

.loader {
  display: inline-block;
  width: 40px;
  height: 40px;
}

.loader:after {
  content: " ";
  display: block;
  width: 30px;
  height: 30px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: loader 1.2s linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}